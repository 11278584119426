<template>
  <div class="big-growers-add">
    <HeaderNavigation type="book" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <TextArea
      v-model="remarks"
      class="textarea mb-12"
      label="备注"
      :showLimit="false"
      :required="false"
    />
    <Navigation ref="navigation" :show-navigation="false" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import Form from "@/components/common/form/index.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Navigation from "@/components/navigation/index.vue";
import TextArea from "@/components/common/textArea/index.vue";
import { terminalOutletsApi } from "@/utils/api.js";
import { getDealerId } from "@/utils/user.js";
export default {
  components: {
    HeaderNavigation,
    Form,
    FooterButton,
    Navigation,
    TextArea,
  },
  data() {
    return {
      remarks: "",
      loading: false,
      detail: {},
      rows: [
        {
          key: "contactPerson",
          label: "联系人",
          required: true,
          type: "input",
        },
        {
          key: "mobile",
          label: "手机",
          required: true,
          type: "input",
          inputType: "tel",
        },
        {
          key: "birthday",
          label: "生日",
          required: true,
          type: "date",
          gap: true,
        },
        {
          key: "regionId",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "regionByDealer",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(this.detail.regionId);
            this.$refs.form.$refs.towninfoId[0].reset();
            this.$refs.form.$refs.villageId[0].reset();
          },
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
          required: true,
          type: "picker",
          dictType: "town",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.villageId[0].fetchVillage(
              this.detail.towninfoId
            );
            this.$refs.form.$refs.villageId[0].reset();
          },
          beforeOpenCheck: () => {
            if (!this.detail.regionId) {
              return "请选择市县";
            }
          },
        },
        {
          key: "villageId",
          label: "所属村",
          required: true,
          type: "picker",
          gap: true,
          dictType: "village",
          valueString: "id",
          labelString: "name",
          beforeOpenCheck: () => {
            if (!this.detail.regionId) {
              return "请选择市县";
            }
            if (!this.detail.towninfoId) {
              return "请选择乡镇";
            }
          },
        },
        {
          key: "runCompBrand",
          label: "经营品牌",
          type: "input",
          required: true,
          gap: true,
          dictType: "crm_compete_brand",
        },
        {
          key: "totalCapacity",
          label: "店内总容量",
          type: "input",
          inputType: "number",
          required: true,
        },
        {
          key: "salesVolume",
          label: "本品销量",
          type: "input",
          inputType: "number",
          required: true,
          gap: true,
        },
        {
          key: "bussinessMan",
          label: "属地化业务员",
          type: "picker",
          required: true,
          gap: true,
          dictType: "salesman",
        },
      ],
    };
  },
  activated() {
    this.detail = {};
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        regionId,
        towninfoId,
        contactPerson,
        villageId,
        mobile,
        birthday,
        totalCapacity,
        salesVolume,
        runCompBrand,
        bussinessMan,
      } = this.detail;
      try {
        await terminalOutletsApi.distSave({
          bussinessMan: bussinessMan || "",
          regionId: regionId || "",
          towninfoId: towninfoId || "",
          contactPerson: contactPerson || "",
          villageId: villageId || "",
          mobile: mobile || "",
          birthday: birthday || "",
          totalCapacity: totalCapacity || "",
          salesVolume: salesVolume || "",
          runCompBrand: runCompBrand || "",
          status: "",
          dealerId: getDealerId(),
          location: this.$refs.navigation.value,
          coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          remarks: this.remarks,
        });
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.big-growers-add {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
  box-sizing: border-box;
}
</style>
