<template>
  <div class="terminal-outlets-info">
    <HeaderNavigation type="book" />
    <Tabs class="tabs">
      <Tab title="基本信息">
        <BasicComponent :detail="detail" :navigation-item="navigationItem" />
      </Tab>
      <Tab title="拜访小结">
        <VisitSummary :navigation-item="navigationItem" @confirm="submit" />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import BasicComponent from "./components/Basic";
import VisitSummary from "@/components/visit/visitSummary.vue";
import { Tabs, Tab } from "vant";
import { terminalOutletsApi } from "@/utils/api.js";
export default {
  components: {
    HeaderNavigation,
    BasicComponent,
    VisitSummary,
    Tabs,
    Tab,
  },
  data() {
    return {
      id: "",
      detail: {},
    };
  },
  computed: {
    navigationItem() {
      const point = this.detail.coordinate || "";
      const [lng, lat] = point.split(",");
      return { lng, lat, position: this.detail.location };
    },
  },
  activated() {
    this.id = this.$route.params.id;
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const response = await terminalOutletsApi.distDetail({ id: this.id });
      this.detail = response.data;
    },
    async submit(value, callback) {
      const {
        visitTip,
        visitVoice,
        visitVoiceLength,
        visitPhoto,
        visitPosition,
        visitCoordinate,
      } = value;
      try {
        await terminalOutletsApi.distSaveVisit({
          kjTerminalNode: this.id,
          visitTip,
          visitVoice,
          visitVoiceLength,
          visitPhoto,
          visitPosition,
          visitCoordinate,
          visitTimes: "",
        });
        callback("");
      } catch (err) {
        callback(err.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.terminal-outlets-info {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 120px;
  box-sizing: border-box;
  .tabs ::v-deep .van-tabs__wrap {
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 4px;
    .van-tabs__nav {
      .van-tab {
        margin: 0 40px;
        font-size: 14px;
      }
    }
  }
}
</style>
