<template>
  <div>
    <Form ref="form" :rows="computedRows" v-model="formData" />
    <TextArea
      v-if="activeName === 'TerminalOutletsShidanliInfo'"
      v-model="remarks"
      class="textarea mb-12"
      label="备注"
      :showLimit="false"
      :required="false"
    />
    <Navigation
      ref="navigation"
      class="mb-12"
      :navigation-item="navigationItem"
    />
    <!-- <RowLink
      :links="[
        {
          label: '近期拜访记录',
          path: `/visit/list?type=terminalOutlets&&id=${id}`,
        },
        {
          label: '账款管理',
          path: `/bill/manage?type=terminalOutlets&&id=${id}`,
        },
      ]"
    /> -->
    <FooterButton
      v-if="activeName === 'TerminalOutletsShidanliInfo'"
      :loading="loading"
      @click="submit"
      >保存</FooterButton
    >
    <FooterDoubleButton
      v-else
      cancelLabel="删除"
      confirmLabel="保存"
      :confirmLoading="loading"
      @cancel="handleDelete"
      @confirm="submit"
    />
  </div>
</template>
<script>
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
// import RowLink from "@/components/select/rowLink.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import FooterDoubleButton from "@/components/common/button/FooterDoubleButton.vue";
import { terminalOutletsApi } from "@/utils/api.js";
import TextArea from "@/components/common/textArea/index.vue";
import { Dialog } from "vant";
export default {
  components: {
    Form,
    Navigation,
    // RowLink,
    FooterButton,
    FooterDoubleButton,
    TextArea,
  },
  data() {
    return {
      id: "",
      loading: false,
      activeName: "",
      formData: {},
      remarks: "",
      rows: [
        {
          key: "terminalType",
          label: "客户类型",
          type: "picker",
          required: true,
          dictType: "门店类型",
          defaultLabel: "terminalTypeName",
        },
        {
          key: "terminalAttribute",
          label: "终端属性",
          type: "picker",
          required: true,
          dictType: "终端属性",
          defaultLabel: "terminalAttributeName",
        },
        {
          key: "shopType",
          label: "店招类型",
          type: "picker",
          required: true,
          dictType: "terminal_shop_type",
          defaultLabel: "shopType",
        },
        {
          key: "shopCode",
          label: "店招编号",
          type: "input",
          gap: true,
        },
        {
          key: "contactPerson",
          label: "联系人",
          type: "input",
          required: true,
        },
        {
          key: "mobile",
          label: "手机",
          type: "input",
          inputType: "tel",
          required: true,
        },
        {
          key: "birthday",
          label: "店主生日",
          type: "date",
          gap: true,
        },
        {
          key: "dealerId",
          label: "所属经销商",
          type: "picker",
          required: true,
          dictType: "dealer",
          defaultLabel: "dealerId",
        },
        {
          key: "regionId",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "region",
          defaultLabel: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(
              this.formData.regionId
            );
            this.$refs.form.$refs.towninfoId[0].reset();
            this.$refs.form.$refs.villageId[0].reset();
            this.$set(this.formData, "town", "");
            this.$set(this.formData, "village", "");
          },
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
          type: "picker",
          required: true,
          dictType: "town",
          defaultLabel: "town",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.villageId[0].fetchVillage(
              this.formData.towninfoId
            );
            this.$refs.form.$refs.villageId[0].reset();
          },
        },
        {
          key: "villageId",
          label: "所属村",
          type: "picker",
          gap: true,
          dictType: "village",
          defaultLabel: "village",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "runCompBrand",
          label: "经营品牌",
          type: "input",
          required: true,
          dictType: "crm_compete_brand",
          defaultLabel: "runCompBrandName",
        },
        {
          key: "otherCompBrand",
          label: "其他竞品品牌",
          type: "input",
          gap: true,
        },
        {
          key: "totalCapacity",
          label: "去年复合肥总销量",
          type: "input",
          inputType: "number",
          required: true,
        },
        {
          key: "salesVolume",
          label: "去年本品复合肥总销量",
          type: "input",
          inputType: "number",
          gap: true,
          required: true,
        },
        {
          key: "workSection",
          label: "经营类型",
          type: "radio",
          data: [
            { key: "化肥", value: "化肥" },
            { key: "种子", value: "种子" },
            { key: "农药", value: "农药" },
            { key: "其他", value: "其他" },
          ],
          required: true,
        },
        {
          key: "brand",
          label: "品牌",
          type: "input",
          required: true,
        },
        {
          key: "bussinessMan",
          label: "属地业务员",
          type: "picker",
          gap: true,
          dictType: "salesman",
          defaultLabel: "bussinessManName",
        },
      ],
      personRows: [
        {
          key: "contactPerson",
          label: "联系人",
          type: "input",
          required: true,
        },
        {
          key: "mobile",
          label: "手机",
          color: "blue",
          gap: true,
          type: "input",
          inputType: "tel",
          required: true,
        },
        {
          key: "regionId",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "region",
          defaultLabel: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(
              this.formData.regionId
            );
            this.$refs.form.$refs.towninfoId[0].reset();
            this.$refs.form.$refs.villageId[0].reset();
            this.$set(this.formData, "town", "");
            this.$set(this.formData, "village", "");
          },
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
          type: "picker",
          required: true,
          dictType: "town",
          defaultLabel: "town",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.villageId[0].fetchVillage(
              this.formData.towninfoId
            );
            this.$refs.form.$refs.villageId[0].reset();
          },
          beforeOpenCheck: () => {
            if (!this.formData.regionId) {
              return "请选择市县";
            }
          },
        },
        {
          key: "villageId",
          label: "所属村",
          gap: true,
          type: "picker",
          required: true,
          dictType: "village",
          defaultLabel: "village",
          valueString: "id",
          labelString: "name",
          beforeOpenCheck: () => {
            if (!this.formData.regionId) {
              return "请选择市县";
            }
            if (!this.formData.towninfoId) {
              return "请选择乡镇";
            }
          },
        },
        {
          key: "otherCompBrand",
          label: "经营品牌",
          type: "input",
          gap: true,
        },
        {
          key: "totalCapacity",
          label: "店内总容量",
          type: "input",
          inputType: "number",
          required: true,
        },
        {
          key: "salesVolume",
          label: "本品销量",
          type: "input",
          inputType: "number",
          required: true,
          gap: true,
        },
        {
          key: "bussinessMan",
          label: "属地业务员",
          type: "picker",
          gap: true,
          dictType: "salesman",
          defaultLabel: "bussinessManName",
        },
      ],
      detailRows: [
        {
          key: "contactPerson",
          label: "客户类型",
        },
        {
          key: "terminalAttribute",
          label: "终端属性",
          gap: true,
        },
        {
          key: "shopType",
          label: "店招类型",
        },
        {
          key: "shopCode",
          label: "店招编号",
          gap: true,
        },
        {
          key: "mobile",
          label: "手机",
          color: "blue",
        },
        {
          key: "birthday",
          label: "店主生日",
          gap: true,
        },
        {
          key: "dealerId",
          label: "所属经销商",
        },
        {
          key: "regionId",
          label: "所属市县",
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
        },
        {
          key: "villageId",
          label: "所属村",
          gap: true,
        },
        {
          key: "runCompBrand",
          label: "经营品牌",
        },
        {
          key: "otherCompBrand",
          label: "其他竞品品牌",
          gap: true,
        },
        {
          key: "totalCapacity",
          label: "去年复合肥总销量",
        },
        {
          key: "salesVolume",
          label: "去年本品复合肥总销量",
          gap: true,
        },
        {
          key: "workSection",
          label: "经营类型",
        },
        {
          key: "brand",
          label: "品牌类型",
        },
        {
          key: "businessMasterName",
          label: "业务主任",
        },
        {
          key: "bussinessMan",
          label: "属地业务员",
          gap: true,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    computedRows() {
      if (this.activeName === "TerminalOutletsShidanliInfo") {
        return this.rows;
      } else if (this.activeName === "TerminalOutletsPersonInfo") {
        return this.personRows;
      } else {
        return this.detailRows;
      }
    },
  },
  watch: {
    detail() {
      this.setDetail();
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        terminalType,
        terminalAttribute,
        shopType,
        shopCode,
        regionId,
        towninfoId,
        contactPerson,
        villageId,
        mobile,
        birthday,
        workSection,
        totalCapacity,
        brand,
        salesVolume,
        runCompBrand,
        otherCompBrand,
        dealerId,
        bussinessMan,
      } = this.formData;
      try {
        if (this.activeName === "TerminalOutletsShidanliInfo") {
          await terminalOutletsApi.save({
            id: this.id,
            bussinessMan: bussinessMan || "",
            terminalType: terminalType || "",
            terminalAttribute: terminalAttribute || "",
            shopType: shopType || "",
            shopCode: shopCode || "",
            regionId: regionId || "",
            towninfoId: towninfoId || "",
            contactPerson: contactPerson || "",
            villageId: villageId || "",
            mobile: mobile || "",
            birthday: birthday || "",
            workSection: workSection || "",
            totalCapacity: totalCapacity || "",
            brand: brand || "",
            salesVolume: salesVolume || "",
            runCompBrand: runCompBrand || "",
            otherCompBrand: otherCompBrand || "",
            dealerId: dealerId || "",
            status: "",
            location: this.$refs.navigation.value,
            coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
            remarks: this.remarks,
          });
        } else {
          await terminalOutletsApi.distSave({
            id: this.id,
            bussinessMan: bussinessMan || "",
            regionId: regionId || "",
            towninfoId: towninfoId || "",
            shopType: shopType || "",
            shopCode: shopCode || "",
            contactPerson: contactPerson || "",
            villageId: villageId || "",
            mobile: mobile || "",
            birthday: birthday || "",
            totalCapacity: totalCapacity || "",
            salesVolume: salesVolume || "",
            otherCompBrand: otherCompBrand || "",
            dealerId: dealerId || "",
            status: "",
            location: this.$refs.navigation.value,
            coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
            remarks: this.remarks,
          });
        }
        this.$toast("保存成功");
        // this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
    async handleDelete() {
      try {
        await Dialog.confirm({
          title: "提示",
          message: "确定要删掉这条数据吗?",
        });
        this.confirmDelete();
      } catch (err) {
        //
      }
    },
    async confirmDelete() {
      try {
        await terminalOutletsApi.distDelete({ id: this.id });
        this.$toast("删除成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
    },
    setDetail() {
      this.formData = {
        ...this.formData,
        ...this.detail,
        birthday: new Date(this.detail.birthday),
      };
      if (this.formData.regionId) {
        this.$refs.form.$refs.towninfoId[0].fetchTown(this.formData.regionId);
      }
      if (this.formData.towninfoId) {
        this.$refs.form.$refs.villageId[0].fetchVillage(
          this.formData.towninfoId
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.remark-wrap {
  padding: 12px 24px;
  margin-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  .title {
    font-size: 14px;
    color: #616266;
    margin-bottom: 12px;
  }
  .remark {
    font-size: 13px;
    line-height: 20px;
    color: #000000;
  }
}
</style>
