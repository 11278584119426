<template>
  <div class="plant-select-wrap">
    <Header text="确定" @textClick="confirm" />
    <div class="type-container">
      <div class="cell">乡镇名</div>
      <div class="cell">村名</div>
      <div class="cell">覆盖</div>
      <div class="cell">是弱势村</div>
    </div>
    <Loading v-show="loading" style="margin-top: 160px" />
    <div v-show="!loading" class="list-container">
      <div class="row" v-for="(item, index) in list" :key="index">
        <div class="cell">{{ item.townName }}</div>
        <div class="cell">{{ item.villageName }}</div>
        <div class="cell">
          <img
            class="check-icon"
            :src="active[item.villageId] ? CheckIcon : NotCheckIcon"
            @click="handleClick(item.villageId)"
          />
        </div>
        <div class="cell">
          <img
            class="check-icon"
            :src="active2[item.villageId] ? CheckIcon : NotCheckIcon"
            @click="handleClick2(item.villageId)"
          />
        </div>
      </div>
    </div>
    <!-- <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more"> </span>
    </infinite-loading> -->
  </div>
</template>

<script>
import CheckIcon from "@/assets/images/common/circle-check.png";
import NotCheckIcon from "@/assets/images/common/not-check.png";
// import InfiniteLoading from "vue-infinite-loading";
import Header from "@/components/header/headerWithText/index.vue";
import { terminalOutletsApi } from "@/utils/api.js";
import { Loading } from "vant";
export default {
  components: {
    Header,
    // InfiniteLoading,
    Loading,
  },
  data() {
    return {
      loading: false,
      CheckIcon,
      NotCheckIcon,
      active: {}, // 覆盖
      active2: {}, // 弱势村
      list: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
    };
  },
  async mounted() {
    const response = await terminalOutletsApi.villageListByTerminal({
      terminalId: this.$route.params.id,
      "page.pageNo": 1,
      "page.pageSize": 1000,
    });
    this.active = (response.data || []).reduce((prev, current) => {
      prev[current.villageId] = true;
      return prev;
    }, {});
    this.active2 = (response.data || []).reduce((prev, current) => {
      prev[current.villageId] = current.isBoruocun === "1";
      return prev;
    }, {});
    this.list = response.data || [];
  },
  methods: {
    handleClick(id) {
      this.$set(this.active, id, !this.active[id]);
    },
    handleClick2(id) {
      this.$set(this.active2, id, !this.active2[id]);
    },
    async confirm() {
      const ids = Object.keys(this.active).filter((id) => {
        return this.active[id];
      });
      this.loading = true;
      try {
        await Promise.all(
          ids.map((id) => {
            return terminalOutletsApi.coverVillageSave({
              terminalId: this.$route.params.id,
              villageId: id,
              isBoruocun: this.active2[id] ? "1" : "0",
            });
          })
        );
        // this.$toast("保存成功");
        this.$router.back();
      } catch (err) {
        // this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.plant-select-wrap {
  font-size: 14px;
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .type-container {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    color: #2c2c2c;
    background: white;
  }
  .cell {
    flex: 1;
  }
  .list-container {
    width: 100%;
    padding-top: 12px;
    .row {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      color: #818181;
      background: white;
    }
    .check-icon {
      width: 17px;
      height: 17px;
    }
  }
}
</style>
