<template>
  <div class="plant-select-wrap">
    <Header text="确定" @textClick="confirm" />
    <div class="type-container">
      <div class="cell">种植作物</div>
      <div class="cell">种植类型</div>
      <div class="cell">覆盖</div>
    </div>
    <Loading v-show="loading" style="margin-top: 160px" />
    <div v-show="!loading" class="list-container">
      <div class="row" v-for="(item, index) in list" :key="index">
        <div class="cell">{{ item.plantName }}</div>
        <div class="cell">{{ item.plantType }}</div>
        <div class="cell">
          <img
            class="check-icon"
            :src="active[item.productId] ? CheckIcon : NotCheckIcon"
            @click="handleClick(item.productId)"
          />
        </div>
      </div>
    </div>
    <!-- <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more"> </span>
    </infinite-loading> -->
  </div>
</template>

<script>
import CheckIcon from "@/assets/images/common/circle-check.png";
import NotCheckIcon from "@/assets/images/common/not-check.png";
// import InfiniteLoading from "vue-infinite-loading";
import Header from "@/components/header/headerWithText/index.vue";
import { terminalOutletsApi } from "@/utils/api.js";
import { Loading } from "vant";
export default {
  components: {
    Header,
    // InfiniteLoading,
    Loading,
  },
  data() {
    return {
      loading: false,
      CheckIcon,
      NotCheckIcon,
      active: {},
      list: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
    };
  },
  async mounted() {
    const data = {
      terminalId: this.$route.params.id,
      "page.pageNo": 1,
      "page.pageSize": 1000,
    };
    const response = await terminalOutletsApi.plantListByTerminal(data);
    this.active = (response.data || []).reduce((prev, current) => {
      prev[current.productId] = true;
      return prev;
    }, {});
    this.list = response.data || [];
  },
  methods: {
    // 废弃
    async infiniteHandler($state) {
      try {
        await this.fetchPlant();
        $state.loaded();
        this.pageNo += 1;
        if (this.total - this.list.length === 0) {
          $state.complete();
        }
      } catch (e) {
        $state.loaded();
        $state.complete();
      }
    },
    // 废弃
    async fetchPlant() {
      const data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      };
      const response = await terminalOutletsApi.plantList(data);
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    handleClick(id) {
      this.$set(this.active, id, !this.active[id]);
    },
    async confirm() {
      // const ids = Object.keys(this.active).filter((id) => {
      //   return this.active[id];
      // });
      this.loading = true;
      try {
        // await Promise.all(
        //   ids.map((id) => {
        //     return terminalOutletsApi.coverPlantSave({
        //       terminalId: this.$route.params.id,
        //       productId: id,
        //     });
        //   })
        // );
        // this.$toast("保存成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.plant-select-wrap {
  font-size: 14px;
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .type-container {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    color: #2c2c2c;
    background: white;
  }
  .cell {
    flex: 1;
  }
  .list-container {
    width: 100%;
    padding-top: 12px;
    .row {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      color: #818181;
      background: white;
    }
    .check-icon {
      width: 17px;
      height: 17px;
    }
  }
}
</style>
