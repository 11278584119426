<template>
  <div class="big-growers-detail">
    <Header class="mb-12" />
    <Form
      ref="form"
      :rows="activeName === 'TerminalOutletsShidanliDetail' ? rows : personRows"
      v-model="detail"
    />
    <div class="remark-wrap">
      <div class="title">备注</div>
      <div class="remark">{{ detail.remarks }}</div>
    </div>
    <Navigation class="mb-12" :navigation-item="navigationItem" />
    <RowLink :links="links" />
  </div>
</template>
<script>
import Header from "@/components/header/index";
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import RowLink from "@/components/select/rowLink.vue";
import { terminalOutletsApi } from "@/utils/api.js";
import dayjs from "dayjs";
export default {
  components: {
    Header,
    Form,
    Navigation,
    RowLink,
  },
  data() {
    return {
      loading: false,
      id: "",
      activeName: "",
      detail: {},
      rows: [
        {
          key: "terminalTypeName",
          label: "客户类型",
        },
        {
          key: "terminalAttributeName",
          label: "终端属性",
          gap: true,
        },
        {
          key: "shopType",
          label: "店招类型",
        },
        {
          key: "shopCode",
          label: "店招编号",
          gap: true,
        },
        {
          key: "contactPerson",
          label: "联系人",
        },
        {
          key: "mobile",
          label: "手机",
          color: "blue",
          type: "tel",
        },
        {
          key: "birthday",
          label: "店主生日",
          gap: true,
        },
        {
          key: "dealerId",
          label: "所属经销商",
        },
        {
          key: "region",
          label: "所属市县",
        },
        {
          key: "town",
          label: "所属乡镇",
        },
        {
          key: "village",
          label: "所属村",
          gap: true,
        },
        {
          key: "runCompBrandName",
          label: "经营品牌",
        },
        {
          key: "otherCompBrand",
          label: "其他竞品品牌",
          gap: true,
        },
        {
          key: "totalCapacity",
          label: "去年复合肥总销量",
        },
        {
          key: "salesVolume",
          label: "去年本品复合肥总销量",
          gap: true,
        },
        {
          key: "workSectionName",
          label: "经营类型",
        },
        {
          key: "brand",
          label: "品牌类型",
        },
        // {
        //   key: "businessMasterName",
        //   label: "业务主任", 不需要展示
        // },
        {
          key: "bussinessManName",
          label: "属地业务员",
          gap: true,
        },
      ],
      personRows: [
        {
          key: "contactPerson",
          label: "联系人",
        },
        {
          key: "mobile",
          label: "手机",
          color: "blue",
          gap: true,
          type: "tel",
        },
        {
          key: "region",
          label: "所属市县",
        },
        {
          key: "town",
          label: "所属乡镇",
        },
        {
          key: "village",
          label: "所属村",
          gap: true,
        },
        {
          key: "runCompBrand",
          label: "经营品牌",
        },
        {
          key: "totalCapacity",
          label: "店内总容量",
        },
        {
          key: "salesVolume",
          label: "本品销量",
          gap: true,
        },
        {
          key: "bussinessManName",
          label: "属地业务员",
          gap: true,
        },
      ],
    };
  },
  computed: {
    links() {
      return this.activeName === "TerminalOutletsShidanliDetail"
        ? [
            {
              label: "近期拜访记录",
              path: `/visit/list?type=terminalOutlets&&id=${this.id}&tab=shidanli`,
            },
            {
              label: "终端销量",
              path: `/sales/terminalSales?type=terminalOutlets&&id=${this.id}`,
            },
            {
              label: "账款管理",
              // path: `/bill/manage?type=terminalOutlets&&id=${this.id}`,
              path: `/bill/terminalManage/1/${this.id}?contactPerson=${this.detail.contactPerson}&contactTel=${this.detail.mobile}&address=${this.detail.region || ''}${this.detail.town || ''}${this.detail.village || ''}`,
            },
          ]
        : [
            {
              label: "近期拜访记录",
              path: `/visit/list?type=terminalOutlets&&id=${this.id}&tab=ziyou`,
            },
          ];
    },
    navigationItem() {
      const point = this.detail.coordinate || "";
      const [lng, lat] = point.split(",");
      return { lng, lat, position: this.detail.location };
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      let response = "";
      if (this.activeName === "TerminalOutletsShidanliDetail") {
        response = await terminalOutletsApi.detail({
          id: this.id,
        });
      } else {
        response = await terminalOutletsApi.distDetail({
          id: this.id,
        });
      }
      this.detail = {
        ...response.data,
        birthday: dayjs(new Date(response.data.birthday)).format("YYYY-MM-DD"),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.big-growers-detail {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 40px;
  .remark-wrap {
    padding: 12px 24px;
    margin-bottom: 12px;
    text-align: left;
    background-color: #ffffff;
    .title {
      font-size: 14px;
      color: #616266;
      margin-bottom: 12px;
    }
    .remark {
      font-size: 13px;
      line-height: 20px;
      color: #000000;
    }
  }
}
</style>
